<template>
  <div :class="$style.container">
    <div :class="$style.toggleContainer">
      Взять управление
      <toggle-button
        v-if="rfb"
        v-model="control"
        :value="control"
        :sync="true"
      />
    </div>
    <div
      id="screen"
      :class="$style.sreenContainer"
    >
      <div :class="$style.description">
        <div :class="$style.status">
          {{ name }}
        </div>

        <div
          v-if="status === 'connected'"
          :class="[$style.status, $style.green]"
        >
          Подключено
        </div>
        <div
          v-if="status === 'disconnected'"
          :class="[$style.status, $style.red]"
        >
          Отключено
        </div>
        <div
          v-if="status === 'error'"
          :class="[$style.status, $style.red]"
        >
          Что-то пошло не так
        </div>
        <div
          v-if="status === 'connecting'"
          :class="[$style.status, $style.yellow]"
        >
          Подключение...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RFB from '@novnc/novnc';
import jwt from 'jsonwebtoken';
import { getComputersAndVncKey } from '@/api/methods/computers';
import Vue from 'vue';
import { ToggleButton } from 'vue-js-toggle-button';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    name: '',
    status: 'connecting',
    rfb: null,
  }),
  computed: {
    control: {
      get() {
        return !this.rfb.viewOnly;
      },
      set() {
        this.$set(this.rfb, 'viewOnly', !this.rfb.viewOnly);
      },
    },
  },
  async created() {
    const { compId } = this.$route.params;

    const res = await getComputersAndVncKey();
    const { key } = res;
    const host = res.sourceHost;
    const port = res.sourcePort;
    const { targetPort } = res;

    const computer = res.result.computers.find((comp) => comp.id === parseInt(compId, 10));
    this.name = computer.nameUser;

    const token = jwt.sign({ host: computer.ipAddress, port: targetPort }, key);

    let url = `${'ws://'}${host}`;

    if (port) {
      url += `:${port}/${token}`;
    }

    const screen = document.getElementById('screen');
    this.rfb = new RFB(screen, url,
      { credentials: { password: computer.vncPass } });

    this.rfb.addEventListener('connect', (e) => {
      if (e) {
        this.status = 'connected';
      }
    });

    this.rfb.addEventListener('disconnect', (e) => {
      if (e) {
        if (e.detail.clean) {
          this.status = 'disconnected';
        } else {
          this.status = 'error';
        }
      }
    });

    this.rfb.addEventListener('clipboard', async (e) => {
      if (e) {
        await navigator.clipboard.writeText(e.detail.text);
      }
    });

    screen.addEventListener('keydown', async (e) => {
      if (e.code === 'KeyV') {
        const clipboard = await navigator.clipboard.readText();
        this.rfb.clipboardPasteFrom(clipboard);
      }
    }, true);

    this.rfb.viewOnly = true;
    this.rfb.showDotCursor = true;
    this.rfb.resizeSession = true;
    this.rfb.scaleViewport = true;
    this.rfb.clipViewport = true;
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.status {
  text-align: center;
  padding: 5px;

  &.green {
    background: $green;
  }

  &.yellow {
    background: $yellow;
  }

  &.red {
    background: $red;
  }

}

.description {
  border: 1px solid $borderColor;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.sreenContainer {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
}

.toggleContainer {
  margin: 20px;
  cursor: default;
  display: flex;
  align-items: center;
  gap: 1%;
}
</style>
